










































































import Vue from 'vue'
import { FormMixin } from '@/components/Mixins'
import { mapActions, mapState } from 'vuex'
import { ITrackerDeviceSetup } from '@/store/modules/trackers/types'

const props = {
  submitText: {
    type: String,
    default: 'Add'
  },
  tracker: {
    type: Object as () => ITrackerDeviceSetup,
    default: null
  },
  apiCallInProgress: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'TrackimoForm',
  props,
  mixins: [FormMixin],
  data() {
    return {
      formValid: false,
      showPassword: false,
      username: null,
      password: null,
      name: null
    }
  },
  mounted() {
    this.initForm()
  },
  computed: {
    ...mapState('sites', ['activeSiteId']),
    usernameRules() {
      if (!this.updateMode)
        return [this.requiredFieldsValidation(this.username, ['password'])]
      return [this.rules.required]
    },
    trackerNameRules() {
      if (!this.updateMode) return []
      return [this.rules.required]
    },
    passwordRules() {
      if (!this.updateMode)
        return [this.requiredFieldsValidation(this.password, ['username'])]
      return [this.rules.required]
    },
    updateMode() {
      return !this.tracker
    }
  },
  methods: {
    initForm() {
      if (this.tracker) {
        this.username = ''
        this.password = ''
        this.name = this.tracker.name
      }
      this.resetValidation()
    },
    resetValidation() {
      this.$refs['tracker-form'].resetValidation()
    },
    resetForm() {
      this.$refs['tracker-form'].reset()
    },
    close() {
      this.$emit('onClose')
    },
    submitClicked() {
      this.$emit('onSubmit', {
        username: this.username,
        password: this.password,
        name: this.name,
        site_id: parseInt(this.activeSiteId)
      })
    },
    requiredFieldsValidation(value: string, required: string[] = []) {
      if (!!value) {
        return (
          required.every(field => !!this[field]) ||
          `${required} ${
            required.length > 1 ? 'fields are' : 'field is'
          } required`
        )
      } else {
        return true
      }
    }
  },
  watch: {
    dialog: {
      handler: function(val) {
        if (!val) {
          this.resetForm()
        }
      }
    }
  }
})
