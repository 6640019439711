


































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Tracker from '@/components/Widgets/Tracker.vue'
const AddTrackerDialog = () => import('@/components/Dialogs/AddTracker.vue')

const props = {
  title: {
    type: String,
    default: 'GPS Trackers'
  },
  editMode: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'Trackers',
  props,
  components: {
    Tracker,
    AddTrackerDialog
  },
  async mounted() {
    if (this.activeSiteId) await this.getTrackimoTrackers(this.activeSiteId)
  },
  computed: {
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sites', ['activeSite']),
    ...mapState('trackers', ['trackers']),
    showSelf() {
      return this.editMode || this.trackers?.length > 0 || false
    }
  },
  methods: {
    ...mapActions('trackers', { getTrackimoTrackers: 'getTrackimoTrackers' }),
    onCloseDialog() {
      this.editMode = false
    }
  },
  watch: {
    activeSiteId: {
      handler: async function(id) {
        await this.getTrackimoTrackers(id)
      }
    }
  }
})
