











































































































































import Vue from 'vue'
import {
  ITrackerGps,
  ITrackerDevice,
  ITrackerDeviceUpdate
} from '@/store/modules/trackers/types'
import TrackerDevice from '@/components/Widgets/TrackerDevice.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import TrackimoForm from '@/components/Dialogs/TrackerForms/Trackimo.vue'

const props = {
  tracker: {
    type: Object as () => ITrackerGps,
    required: true
  },
  editMode: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'TrackerItem',
  components: { TrackimoForm, TrackerDevice },
  props,
  data() {
    return {
      error: false as boolean,
      errorMessage: null as string,
      apiCallInProgress: false as boolean,
      showEditDialog: false as boolean,
      showDeleteDialog: false as boolean
    }
  },
  computed: {
    ...mapGetters('sites', ['activeSiteId']),
    ...mapState('real_drone', ['detections']),
    devices(): ITrackerDevice[] {
      return this.tracker.devices
    },
    siteId(): number {
      return parseInt(this.activeSiteId)
    }
  },
  methods: {
    ...mapActions('trackers', {
      updateTrackimoTracker: 'updateTrackimoTracker',
      deleteTrackimoTracker: 'deleteTrackimoTracker',
      getTrackimoTrackers: 'getTrackimoTrackers'
    }),
    isDeviceDetected(device_id: number) {
      return this.detections.some(
        detection => detection.device_id === device_id
      )
        ? 'green'
        : 'red'
    },
    async deleteTrackerClicked() {
      this.apiCallInProgress = true
      const response = await this.deleteTrackimoTracker({
        site_id: this.siteId,
        id: this.tracker.id
      })

      if (!response.error) {
        await this.getTrackimoTrackers(this.siteId)
        this.showDeleteDialog = false
      } else {
        this.error = true
      }
      this.apiCallInProgress = false
    },
    async editTrackerClicked(payload) {
      this.apiCallInProgress = true
      const requestData = {}
      Object.keys(payload).forEach(key => {
        if (payload[key] !== '') {
          requestData[key] = payload[key]
        }
      })

      const editData = {
        id: this.tracker.id,
        payload: requestData
      } as ITrackerDeviceUpdate

      const response = await this.updateTrackimoTracker(editData)

      if (!response.error) {
        await this.getTrackimoTrackers(this.siteId)
        this.showEditDialog = false
      } else {
        this.errorMessage = response.data
        this.error = true
      }
      this.apiCallInProgress = false
    },
    closeDialog() {
      this.showEditDialog = false
      this.showDeleteDialog = false
      this.error = false
      this.errorMessage = null
    }
  }
})
