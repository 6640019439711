



























import Vue from 'vue'
import { ITrackerDevice } from '../../store/modules/trackers/types'
import { mapActions, mapGetters, mapState } from 'vuex'

const props = {
  trackerId: {
    type: Number,
    required: true
  },
  device: {
    type: Object as () => ITrackerDevice,
    required: true
  },
  lastDevice: {
    type: Boolean,
    default: false
  },
  editMode: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'TrackerDevice',
  props,
  computed: {
    ...mapGetters('sites', ['activeSiteId'])
  },
  methods: {
    ...mapActions('trackers', {
      updateTrackimoStreamingState: 'updateTrackimoStreamingState'
    }),
    async streamingChanged(state: boolean) {
      await this.updateTrackimoStreamingState({
        drone_gps_id: this.trackerId,
        id: this.device.device_id,
        state: state,
        site_id: parseInt(this.activeSiteId)
      })
    }
  }
})
